<template>
  <div class="auth-container">
    <div class="auth-container__header">
      <h1>{{ $t('main.register') }}</h1>
      <p>{{ $t('main.registerGreeting') }}</p>
    </div>
    <p class="auth-container__error">
      {{ registrationError.length > 0 ? registrationError : ' ' }}
    </p>
    <div class="auth-container__form">
      <form autocomplete="off" action="#" onsubmit="return false">
        <BInputField
          v-model="username"
          class="auth-container__form--spacing"
          :label="$t('fields.username') + ' *'"
          type="username"
          compact
          data-cy="username"
          @hasError="(error) => (usernamHasError = error)"
        />
        <BInputField
          v-model="firstName"
          class="auth-container__form--spacing"
          :label="$t('fields.firstName')"
          type="text"
          data-cy="firstName"
          compact
        />
        <BInputField
          v-model="lastName"
          class="auth-container__form--spacing"
          :label="$t('fields.lastName')"
          type="text"
          data-cy="lastName"
          compact
        />
        <BInputField
          v-model="email"
          class="auth-container__form--spacing"
          :label="$t('fields.email') + ' *'"
          type="userEmail"
          data-cy="email"
          compact
          @hasError="(error) => (emailHasError = error)"
        />
        <BInputField
          v-model="password"
          class="auth-container__form--spacing"
          :label="$t('fields.password') + ' *'"
          type="password"
          right-icon="show"
          data-cy="password"
          compact
          @hasError="(error) => (passwordHasError = error)"
        />
        <BButton
          fill
          block
          :disabled="!terms || invalidFields"
          data-cy="registerButton"
          @click.native="register()"
        >
          {{ $t('main.register') }}
        </BButton>
        <br />
        <div class="auth-container__terms">
          <input
            id="terms"
            v-model="terms"
            type="checkbox"
            data-cy="tosCheckbox"
          />
          <label for="terms">
            {{ $t('main.registerInfo') }}
            <a
              target="_blank"
              :href="$whitelabel.links.dataPrivacy"
              @click.stop
            >
              {{ $t('main.dataPrivacy') }}
            </a>
            {{ ' ' }}{{ $t('main.and') }}
            <a
              target="_blank"
              :href="$whitelabel.links.termsOfService"
              @click.stop
            >
              {{ $t('main.termsOfService') }}
            </a>
          </label>
        </div>
      </form>
      <div class="auth-container__register">
        <p>
          {{ $t('main.existingAccount') }}
          <a data-cy="goToLoginLink" @click.stop.prevent="gotoLogin()">{{
            $t('main.loginTitle')
          }}</a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import BInputField from '@/components/general/BInputField.vue';
import BButton from '@/components/general/BButton.vue';
import { parseJwt } from '~/utils/utils';
import { toCompleteRegistrationEvent } from '~/utils/facebook';

export default {
  name: 'BRegister',
  components: {
    BInputField,
    BButton
  },
  emits: ['gotoLogin'],
  data() {
    return {
      username: '',
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      registrationError: '',
      terms: false,
      usernamHasError: false,
      emailHasError: false,
      passwordHasError: false
    };
  },
  computed: {
    invalidFields() {
      return (
        this.usernamHasError ||
        this.emailHasError ||
        this.passwordHasError ||
        this.username.length <= 0 ||
        this.email.length <= 0 ||
        this.password.length <= 0
      );
    }
  },
  methods: {
    gotoLogin() {
      this.$emit('gotoLogin');
    },
    async register() {
      const userPass = {
        password: this.password,
        username: this.username
      };
      const register = {
        ...userPass,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName
      };
      try {
        const registerRes = await this.$bamApi.account.createUser(register);
        if (this.$enableMetaTracking) {
          if (this.$fb.options.metaPixelId !== this.$metaPixelId) {
            this.$fb.init(this.$metaPixelId, { em: this.email });
          }
          this.$fb.enable();
          this.$fb.track(
            'CompleteRegistration',
            toCompleteRegistrationEvent(registerRes)
          );
          this.$fb.disable();
        }

        const res = await this.$auth.loginWith('local', {
          data: userPass
        });
        if (res.data) {
          this.setAuth(res);
          document.body.style = '';
        }
      } catch (error) {
        this.registrationError = error?.response?.data?.message;
      }
    },
    async fetchUserInfo(token) {
      const tokenInfo = parseJwt(token);
      const user = await this.$services.account.getUser(tokenInfo.id);
      this.$auth.setUser({
        ...user,
        ...tokenInfo
      });
      this.$store.commit('user/setUser', user);
    },
    async setAuth(res) {
      await this.$auth.setUserToken(
        'Bearer ' + res?.data?.data?.token,
        res?.data?.data?.refreshToken
      );
      this.$api.setHeader('Authorization', 'Bearer ' + res?.data?.data?.token);
      await this.fetchUserInfo(res?.data?.data?.token);
      this.$store.commit('app/setAuthDialog', false);
    }
  }
};
</script>
